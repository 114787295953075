<template>
  <app-header :routes="routes" />
  <confirm-modal
    :title="$t('views.confirm.removeDestination.title')"
    :confirmMessage="$t('views.confirm.removeDestination.message')"
    :showConfirm="confirmModalVisible"
    @confirm:ok="submitForm"
    @confirm:cancel="confirmModalVisible = false">
  </confirm-modal>
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <sdCards headless>
           <app-form :fields="fields"
                     :rules="rules"
                     @input-changed="inputChanged"
                     @forceUpdated="handleForceUpdate"
                     @submitted="handleSubmit"
                     :loading="loading"
                     :submitButton="{type: 'primary', block: false, label: 'actions.saveAndNext',
                                     icon: 'arrow-right', classes: 'float-right'}" />
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import {
  defineComponent, reactive, ref, computed, watch
} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import _ from 'lodash';
import {shipmentRequestTypes, shippingMethods} from '@/constants';
import {required} from '@/helpers/validationRules';
import {Main} from '@/components/shared/styledComponents/Main';
import ConfirmModal from '@/components/modals/ConfirmModal';
import {extractActiveListOptions} from '@/helpers/dynamicListsOptions';

export default defineComponent({
  name: 'ShipmentRequestForm',
  components: {
    Main, ConfirmModal
  },
  emits: ['submitForm', 'previous'],
  setup() {
    const {t} = useI18n();
    const route = useRoute();
    const store = useStore();

    const edit = computed(() => route.name === 'ClientEditShipmentRequest');
    const clone = computed(() => route.name === 'ClientCloneShipmentRequest');

    const status = ref('process');
    const isFinished = ref(false);

    const routes = computed(() =>[
      {
        name: 'ClientShipmentRequests',
        breadcrumbName: 'layout.shipmentRequest.index'
      },
      {
        name: edit.value ? 'ClientEditShipmentRequest' : clone.value ? 'ClientCloneShipmentRequest' : 'ClientNewShipmentRequest',
        params: edit.value || clone.value ? {shipmentRequestId} : {},
        breadcrumbName: edit.value ? 'views.shipmentRequest.edit' : clone.value ? 'views.shipmentRequest.clone': 'layout.shipmentRequest.new'
      }
    ]);

    const getDynamicLists = async () => {
      store.dispatch('toggleLoading', true);
      await Promise.all([
        store.dispatch('getCountries', {exclude_sanctioned: true}),
        store.dispatch('getList', 'IOR_SCOPES'),
        store.dispatch('getList', 'EOR_SCOPES')]);
      if (!edit.value && !clone.value) {
        store.dispatch('toggleLoading', false);
      }
    };

    const countries = computed(() => store.getters.countries);
    const iorScopes = computed(() => {
      return extractActiveListOptions(store.getters.iorScopes, shipmentRequest.value, 'scopeId');
    });
    const eorScopes = computed(() => {
      return extractActiveListOptions(store.getters.eorScopes, shipmentRequest.value, 'scopeId');
    });

    const resetForm = ref(false);
    const fields = reactive({
      shipmentType: {
        type: 'select',
        label: 'models.shipmentRequest.attributes.shipmentType',
        fieldValue: '',
        trackable: true,
        options: [
          {
            value: shipmentRequestTypes.IOR, label: t('models.shipmentRequest.attributes.shipmentTypes.IOR')
          },
          {
            value: shipmentRequestTypes.EOR, label: t('models.shipmentRequest.attributes.shipmentTypes.EOR')
          }
        ],
        styles: {md: 12}
      },
      scopeId: {
        type: 'select',
        label: 'models.shipmentRequest.attributes.scope',
        fieldValue: '',
        options: [],
        styles: {md: 12},
        noDataMessage: 'views.shipmentRequest.selectShipmentTypeToViewScopes'
      },
      shippingFrom: {
        type: 'select',
        label: 'models.shipmentRequest.attributes.shippingFrom',
        fieldValue: '',
        options: computed(() => store.getters.countries),
        enableSearch: true,
        styles: {md: 12},
        icon: 'map-pin'
      },
      destinations: {
        type: 'multipleSelect',
        label: 'models.shipmentRequest.attributes.destinationsWithHelp',
        fieldValue: [],
        options: computed(() => store.getters.countries),
        enableSearch: true,
        styles: {md: 12},
        icon: 'map-pin'
      },
      shippingMethod: {
        type: 'select',
        label: 'models.shipmentRequest.attributes.shippingMethod',
        fieldValue: '',
        options: _.map(shippingMethods, (value) => {
          return {label: t(`models.shipmentRequest.attributes.shippingMethods.${value}`), value};
        }),
        styles: {md: 12}
      },
      reference: {
        type: 'text',
        label: 'models.shipmentRequest.attributes.reference',
        fieldValue: '',
        styles: {md: 12}
      }
    });

    const rules = {
      shipmentType: [required],
      scopeId: [required],
      shippingFrom: [required],
      destinations: [{...required, type: 'array'}],
      shippingMethod: [required]
    };

    const inputChanged = ({key, value}) => {
      if (key === 'shipmentType') {
        const scopes = value === shipmentRequestTypes.IOR ? iorScopes.value : eorScopes.value;
        fields.scopeId.options = _.map(scopes, (value) => value);
        fields.scopeId.fieldValue = '';
        fields.scopeId['forceUpdate'] = true;
      }
    };

    const handleForceUpdate = (keys) => _.forEach(keys, (key) => fields[key]['forceUpdate'] = false);

    const shipmentRequest = computed(() => store.getters.shipmentRequest);
    let shipmentRequestId;
    watch(() => route.name, async () => {
      if (route.name === 'ClientNewShipmentRequest' || route.name === 'ClientEditShipmentRequest' || route.name === 'ClientCloneShipmentRequest') {
        await getDynamicLists();
      }
      if (edit.value || clone.value) {
        shipmentRequestId = route.params.shipmentRequestId;
        if (!Object.keys(shipmentRequest.value).length) {
          await store.dispatch('getShipmentRequest', shipmentRequestId);
        }
        inputChanged({key: 'shipmentType', value: shipmentRequest.value.shipmentType});
        _.forEach(fields, (field, key) => {
          field.fieldValue = key === 'scopeId' ? `${shipmentRequest.value[key]}` : shipmentRequest.value[key];
          field['forceUpdate'] = true;
        });
        fields.shipmentType['disabled'] = true;
      } else {
        resetForm.value = true;
      }
      store.dispatch('toggleLoading', false);
    }, {immediate: true});

    const confirmModalVisible = ref(false);
    const submittedData = reactive({});
    const handleSubmit = (fields) => {
      Object.assign(submittedData, fields);
      if (_.difference(shipmentRequest.value.destinations, fields.destinations).length > 0 && edit.value) {
        confirmModalVisible.value = true;
      } else {
        submitForm();
      }
    };

    const loading = ref(false);
    const submitForm = async () => {
      loading.value = true;
      const data = {};
      _.forEach(submittedData, (value, key) => {
        if (value) {
          data[_.snakeCase(key)] = value;
        }
      });
      if (edit.value) {
        await store.dispatch('updateShipmentRequest', {form: data, shipmentRequestId});
      } else if (clone.value) {
        await store.dispatch('cloneShipmentRequest', {form: data, shipmentRequestId});
      } else {
        await store.dispatch('createShipmentRequest', data);
      }
      loading.value = false;
    };

    return {
      fields,
      rules,
      edit,
      clone,
      routes,
      countries,
      resetForm,
      status,
      isFinished,
      loading,
      confirmModalVisible,
      handleSubmit,
      submitForm,
      handleForceUpdate,
      inputChanged
    };
  }
});
</script>
